<div class="orders-container">
  <p-card class="orders-card">
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-icon-input
          [keyword]="'RESEARCH'"
          [icon]="'search'"
          (OnValueChange)="onSearchParamChange($event)">
        </app-prolabo-icon-input>
      </div>
    </div>
    <div class="warning-message" *ngIf="user?.role_id === 3">
      {{ 'INVOICES.WARNING' | translate }}
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-invoices-pagination-list [items]="invoices"
                                              [headers]="headers"
                                              [total]="total"
                                              [user]="user"
                                              (itemDelete)="deleteInvoice($event)"
                                              (limitValue)="changeLimit($event)"
                                              (pageValue)="changeOffset($event)"
                                              (invoicePreview)="getPdf($event)"
                                              (filterByStatusEvent)="handleStatusFilter($event)"
                                              (sortValue)="sortByLabel($event)">
        </app-prolabo-invoices-pagination-list>
      </div>
    </div>
  </p-card>
</div>
